<template>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-12 gap-x-2">
        <div class="box col-span-7 bg-white h-full cust_card">
          <div>
            <div class="p-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Page Title</p>
                <input v-model="pageObj.pageName"
                :class="pagnameErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Page Title"
                >
                <p v-if="pagnameErr" class="text-error heading-6">Page Name is Required</p>
              </div>
            </div>
            <div class=" pt-0 grid grid-cols-12 border border-gray-400 m-4 rounded" v-for="(data, index) in pageObj.pageSections" :key="index">
              <div class="box " :class="pageObj.pageSections.length > 1 ? 'col-span-11 p-4 pr-0' : 'col-span-12 p-4'">
                <p class="label_css">Section Name</p>
                <input v-model="data.sectionName"
                :class="data.sectionErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Section Name"
                @keyup="changeUnderScore(data.sectionName, 'sName', index)"
                >
                <p v-if="data.sectionErr" class="text-error heading-6">Section Name is Required</p>
              </div>
              <div class="p-4 pt-0 box col-span-12" v-if="data.isShow && data.pageSectionFields !== null">
                <!-- <draggable :list="data.pageSectionFields" group="people" @start="drag = true" @end="dragArray" id='list'> -->
                  <div class="grid grid-cols-12 gap-x-3" v-for="(childData, childIndex) in data.pageSectionFields" :key="childIndex">
                    <div class=" pt-2 box col-span-6" v-if="childData.isExist">
                      <p class="label_css">Component{{childData.isRepeated === true ? ' (Is Repeatable)' : ''}}</p>
                      <input v-model="childData.selComponentName"
                      disabled
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                      placeholder="Section Name"
                      >
                    </div>
                    <div class=" pt-2 box col-span-6" v-if="!childData.isExist">
                      <p class="label_css">Type</p>
                      <input v-model="childData.fieldDataType"
                      disabled
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                      placeholder="Section Name"
                      >
                    </div>
                    <div class=" pt-2" :class="data.pageSectionFields.length > 1 ? 'box col-span-5' : 'box col-span-6'">
                      <p class="label_css">Display Name</p>
                      <input v-model="childData.fieldDisplayName"
                      disabled
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                      placeholder="Section Name"
                      >
                    </div>
                  <div style="position:absolute;justify-self:right;margin-top: 35px;" class="">
                    <button v-if="data.pageSectionFields.length > 1" class=" rounded-full h-7 w-7 bg-red-500 mr-1 cursor-pointer"
                      @click="removeField(childIndex, index)"
                      >
                      <i class="fas fa-trash text-white heading-4 pt-1"></i>
                    </button>
                  </div>
                    <!-- <div style="position:absolute;margin-top: 35px;right: 41%;" class="">
                      <button class=" rounded-full h-7 w-7 mr-1 cursor-pointer"
                        @click="removeField(childIndex, index)"
                        >
                        <i class="fa-solid fa-grip-vertical text-text2 heading-4 pt-1"></i>
                      </button>
                    </div> -->
                  </div>
                <!-- </draggable> -->
              </div>
              <div class="p-2 pt-0 flex box col-span-12">
                <p class=" text-primary pl-2 heading-5 cursor-pointer" @click="addField(index)">+ Add Field</p>
              </div>
              <div style="position:absolute;justify-self:right;margin-top: 47px;" class="pr-4 -mt-4">
                <button v-if="pageObj.pageSections.length > 1" class=" rounded-full h-7 w-7 bg-red-500 mr-1 cursor-pointer"
                  @click="removeSection(index)"
                  >
                  <i class="fas fa-trash-alt text-white heading-4 pt-1"></i>
                </button>
              </div>
            </div>
            <div class="p-2 pt-0 flex box col-span-12">
              <p class=" text-primary pl-2 heading-5 cursor-pointer" @click="addSection()">+ Add Section</p>
            </div>

          </div>
          <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="$router.go(-1)">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveNewPage()">Save</button>
            </div>  
          </div>
        </div>
      </div>
      <div v-if="addFieldPopup" class="popup_overlay">
        <div persistent class="custom_dialog" style="width:800px" >
          <div class="rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
            <div>
              <p class="">Add Field</p>
            </div>
            <div class="flex gap-2">
              <button class="mr-2 rounded-3xl heading-4 py-2 px-6 bg-gray-300 text-black" @click="addFieldPopup = false">Discard</button>
              <button class="mr-2 rounded-3xl heading-4 py-2 px-6 bg-success text-white" @click="saveAddedField()">Save</button>
            </div>  
          </div>
          <div class="bg-white p-2 px-4 ">
            <div class="grid grid-cols-12">
              <div class="flex items-center pb-1 col-span-6">
                <input id="default-radio-1" v-model="radioButtonVal" type="radio" value="newField" name="default-radio 1" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                <label for="default-radio-1" class="ml-2 heading-4 text-text2  dark:text-gray-300">New Field</label>
              </div>
              <div class="flex items-center pb-1 col-span-6">
                <input id="default-radio-2" v-model="radioButtonVal" type="radio" value="useExistingComponent" name="default-radio 2" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                <label for="default-radio-2" class="ml-2 heading-4 text-text2  dark:text-gray-300">Use Existing Component</label>
              </div>
            </div>
            <div class="pt-0 grid grid-cols-12" v-if="!isRepField">
              <div class="box col-span-6 p-2">
                <div class="bg-white rounded">
                  <div class="w-full">
                    <div class="" >
                      <p class="label_css">Select Field</p>
                      <div class="py-1 p-2 flex border border-gray-500 bg-white shadow rounded" @click.stop="showPopupBranch()" >
                        <span class="place-self-center flex">
                          <div class="flex flex-auto flex-wrap" >
                            <input placeholder="Select Field" @input="searchValue(fieldObj.fieldName)" v-model="fieldObj.fieldName"
                            class="w-80 h-9 cursor-pointer  focus:outline-none heading-4 text-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500">
                          </div>
                          <p v-if="displayEmpSelect" class="self-center"><i class="fas fa-chevron-up"></i></p>
                          <span v-if="!displayEmpSelect" class="self-center"><i class="fas fa-chevron-down"></i></span>
                        </span>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg" style="overflow-x: auto;width: 404px;position: fixed;" v-if="displayEmpSelect && shortedList.length > 0" v-click-outside="hidePopupBranch">
                        <div v-for="(cell, indexA) in shortedList" :key="indexA" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1s"
                        :class="fieldObj.fieldMasterId === cell.fieldMasterId ? 'bg-blue-100' : '' "
                        @click="selectName(cell, indexA)">
                          <div class=" pl-2">
                            {{ cell.fieldName }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-col w-full max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg absolute z-50" style="overflow-x: auto;width: 404px;position: fixed;" v-if="displayEmpSelect && shortedList.length === 0" v-click-outside="hidePopupBranch">
                        <div class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1s">
                          <div class=" pl-2">
                            No record found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box p-2 col-span-6">
                <p class="label_css">Display Name</p>
                <input
                @keyup="changeUnderScore(fieldObj.fieldDisplayName, 'dName', '0')"
                class="outline-none text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Display Name"
                v-model="fieldObj.fieldDisplayName"
                >
              </div>
            </div>
            <div class="pt-0 grid grid-cols-12" v-if="isRepField">
              <div class="box p-2 col-span-6">
                <p class="label_css">Display Name</p>
                <input
                @keyup="changeUnderScore(fieldObj.fieldDisplayName, 'd2Name', '0')"
                class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Display Name"
                v-model="fieldObj.fieldDisplayName"
                >
              </div>
              <div class="box col-span-6 p-2">
                <div class="bg-white rounded">
                  <div class="w-full">
                    <div class="" >
                      <p class="label_css">Select Component</p>
                      <div class="py-1 p-2 flex border border-gray-500 bg-white shadow rounded" @click.stop="showPopupBranch()" >
                        <span class="place-self-center flex">
                          <div class="flex flex-auto flex-wrap" >
                            <input placeholder="Select Component" v-model="fieldObj.selComponentName" @input="getComListShort(fieldObj.selComponentName)"
                            class="w-80 h-9 cursor-pointer  focus:outline-none heading-4 text-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500">
                          </div>
                          <p v-if="displayEmpSelect" class="self-center"><i class="fas fa-chevron-up"></i></p>
                          <span v-if="!displayEmpSelect" class="self-center"><i class="fas fa-chevron-down"></i></span>
                        </span>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg" style="overflow-x: auto;width: 404px;position: fixed;" v-if="displayEmpSelect && componentList.length > 0" v-click-outside="hidePopupBranch">
                        <div v-for="(cell, indexA) in componentList" :key="indexA" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" style="overflow-wrap: anywhere !important;"
                        :class="fieldObj.componentMasterId === cell.componentMasterId ? 'bg-blue-100' : '' "
                        @click="selectComponent(cell, indexA)">
                          <div class=" pl-2">
                            {{ cell.componentName }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-300 shadow-lg" style="overflow-x: auto;width: 370px;position: fixed;" v-if="displayEmpSelect && componentList.length === 0" v-click-outside="hidePopupBranch">
                        <div class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" 
                       >
                          <div class="pl-2">
                            no Record found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center pb-1 ">
                  <input id="default-radio-3" v-model="isRepetedCom" type="radio" value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300">Single Component</label>
                </div>
                <div class="flex items-center pb-1 col-span-6">
                  <input id="default-radio-4" v-model="isRepetedCom" type="radio" value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300">Repeatable Component</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="deleteSecConfPopup" class="popup_overlay">
        <div style="height: 120px; width: 500px;" class="custom_dialog">
          <div class="text-text1 px-4 py-3 flex justify-between items-center">
            <span class="heading-3">Are You Sure ?</span>
            <div class="flex justify-end">
              <button class="bg-error text-white shadow mr-2 rounded-full px-5 py-1.5" @click="deleteSecConfPopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="removesectionConf()">Yes</button>
            </div>
          </div>
          <hr>
          <div class="p-4">
            <p class="text-gray3 heading-4">Once delete cannot be undone !</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import draggable from 'vuedraggable'
import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    // draggable,
  },
  data () {
    return {
      tempIndex: 0,
      deleteSecConfPopup: false,
      drag: false,
      pageDetailObj: [],
      shortedList: [],
      pagnameErr: false,
      isError: true,
      componentList: [],
      isRepField: false,
      displayEmpSelect: false,
      inputFieldList: [],
      radioButtonVal: 'newField',
      isRepetedCom: 'false',
      addFieldPopup: false,
      addFieldIndex: 0,
      fieldObj: {
        fieldName: '',
        pageSectionFiledId: 0,
        componentMasterId: 0,
        selComponentName: '',
        fieldMasterId: 0,
        fieldDataType: '',
        fieldDisplayName: '',
        isRepeated: false,
        isExist: false
      },
      pageObj: {
        pageMasterId: 0,
        projectDetailId: 0,
        pageName: '',
        isActive: true,
        pageSections: [
          {
            pageSectionMappingId: 0,
            sectionName: '',
            displayOrder: 0,
            sectionErr: false,
            isShow: false,
            pageSectionFields: [
              {
                pageSectionFiledId: 0,
                componentMasterId: 0,
                fieldMasterId: 0,
                fieldDisplayName: '',
                fieldDataType: '',
                selComponentName: '',
                isRepeated: false,
                isExist: false
              }
            ]
          }
        ]
      }
    }
  },
  created() {
  },
  mounted () {
    document.title = this.$route.params.pageMasterId > 0 ? 'Edit Page' : 'add New Page'
    console.log('this.$123 route.params', this.$route.params)
    console.log('this.$123 route.name', this.$route.name)
    console.log('this.$123 router', this.$router)
    if (JSON.parse(this.$route.params.pageMasterId) > 0) {
      this.getPageStructDetail()
    }
    this.inputFieldReqItem()
    this.getComponentList('')
  },
  watch: {
    pageObj: {
      handler () {
        console.log('pageObj 123', this.pageObj)
        if (this.pageObj.pageName !== '') {
          this.pagnameErr = false
          this.isError = false
        }
        for (let index = 0; index < this.pageObj.pageSections.length; index++) {
          if (this.pageObj.pageSections[index].sectionName !== '') {
            this.pageObj.pageSections[index].sectionErr = false
            this.isError = false
          }
        }
      },
      deep: true
    },
    radioButtonVal: {
      handler () {
        console.log('radioButtonVal 123', this.radioButtonVal)
        if (this.radioButtonVal === 'newField') {
          this.isRepField = false
          this.fieldObj.isExist = false
        } else {
          this.isRepField = true
          this.fieldObj.isExist = true
        }
        this.displayEmpSelect = false
        this.fieldObj.pageSectionFiledId = 0
        this.fieldObj.componentMasterId = 0
        this.fieldObj.fieldMasterId = 0
        this.fieldObj.fieldDisplayName = ''
        this.fieldObj.selComponentName = ''
        this.fieldObj.fieldDataType = ''
        this.fieldObj.isRepeated = false
        this.getComponentList('')
        console.log('this.fieldObj.isExist ', this.fieldObj.isExist)
      },
      deep: true
    },
    isRepetedCom: {
      handler () {
        console.log('radioButtonVal 123', this.isRepetedCom)
        if (this.isRepetedCom === 'true') {
          this.fieldObj.isRepeated = true
        } else {
          this.fieldObj.isRepeated = false
        }
      },
      deep: true
    }
  },
  methods: {
    getComListShort (value) {
      this.getComponentList(value)
    },
    searchValue (value) {
      console.log('value,', value)
      let temp = this.inputFieldList
      console.log('this.temp,', temp)
      if (value === '') {
        this.shortedList = temp
        console.log('value, bull')
      } else {
        let filter = value.toLowerCase()
        this.shortedList = []
        for (let index = 0; index < temp.length; index++) {
          if (value.toLowerCase().indexOf(filter) > -1 === temp[index].fieldName.toLowerCase().indexOf(filter) > -1) {
           this.shortedList.push(temp[index])
          }
        }
      }
      console.log('value, temp', this.shortedList)
    },
    changeUnderScore (value, from, index) {
      if (from === 'sName') {
        this.pageObj.pageSections[index].sectionName = value.replace(/ /g,"_")
      } else if (from === 'dName') {
        this.fieldObj.fieldDisplayName = value.replace(/ /g,"_")
      } else if (from === 'd2Name') {
        this.fieldObj.fieldDisplayName = value.replace(/ /g,"_")
      }
    },
    showPopupBranch() {
      this.displayEmpSelect = true
    },
    hidePopupBranch() {
      this.displayEmpSelect = false
    },
    selectName(val, listIndex) {
      console.log('val', val)
      console.log('index', listIndex)
      this.fieldObj.fieldDataType = val.fieldDataType
      this.fieldObj.fieldMasterId = val.fieldMasterId
      this.fieldObj.fieldName = val.fieldName
      this.fieldObj.componentMasterId = 0
      this.displayEmpSelect = false
    },
    dragArray () {
      this.drag = false
    },
    selectComponent(val, listIndex) {
      console.log('val', val)
      console.log('index', listIndex)
      this.fieldObj.componentMasterId = val.componentMasterId
      this.fieldObj.selComponentName = val.componentName
      this.fieldObj.fieldMasterId = 0
      this.displayEmpSelect = false
    },
    saveAddedField () {
      console.log('fieldObj', this.fieldObj)
      if (this.pageObj.pageSections[this.addFieldIndex].pageSectionFields === null) {
        this.pageObj.pageSections[this.addFieldIndex].pageSectionFields = []
      }
      if (this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.length === 1) {
        let value = this.pageObj.pageSections[this.addFieldIndex].pageSectionFields[0]
        if (value.fieldMasterId === 0 && value.componentMasterId === 0 && value.fieldDisplayName === '') {
          this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.splice(0, 1)
        }
      }
      // if (this.radioButtonVal === 'useExistingComponent' && this.isRepetedCom === 'false') {
        
      // }
      this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.push(
        {
          pageSectionFiledId: this.fieldObj.pageSectionFiledId,
          componentMasterId: this.fieldObj.componentMasterId,
          fieldMasterId: this.fieldObj.fieldMasterId,
          fieldDisplayName: this.fieldObj.fieldDisplayName,
          selComponentName: this.fieldObj.selComponentName,
          fieldDataType: this.fieldObj.fieldDataType,
          fieldName: this.fieldObj.fieldName,
          isRepeated: this.fieldObj.isRepeated,
          isExist: this.fieldObj.isExist
        }
      )
      this.fieldObj.pageSectionFiledId = 0
      this.fieldObj.componentMasterId = 0
      this.fieldObj.fieldMasterId = 0
      this.fieldObj.fieldDisplayName = ''
      this.fieldObj.selComponentName = ''
      this.fieldObj.fieldDataType = ''
      this.fieldObj.fieldName = ''
      this.fieldObj.isRepeated = false
      this.isRepetedCom = 'false'
      this.radioButtonVal = 'newField'
      this.pageObj.pageSections[this.addFieldIndex].isShow = true
      this.addFieldPopup = false
    },
    addField(index) {
      this.addFieldIndex = index
      this.addFieldPopup = true
      console.log('add field')
      this.inputFieldReqItem()
    },
    addSection() {
      console.log('add section')
      this.pageObj.pageSections.push(
        {
          pageSectionMappingId: 0,
          sectionName: '',
          sectionErr: false,
          displayOrder: 0,
          isShow: false,
          pageSectionFields: [
            {
              pageSectionFiledId: 0,
              componentMasterId: 0,
              fieldMasterId: 0,
              fieldDisplayName: '',
              selComponentName: '',
              fieldDataType: '',
              fieldName: '',
              isRepeated: false
            }
          ]
        }
      )
    },
    removeSection(index) {
      this.tempIndex = index
      this.deleteSecConfPopup = true
      // if (this.pageObj.pageSections.length !== 1) {
      //   this.pageObj.pageSections.splice(index, 1)
      // }
    },
    removesectionConf() {
      this.deleteSecConfPopup = false
      if (this.pageObj.pageSections.length !== 1) {
        this.pageObj.pageSections.splice(this.tempIndex, 1)
      }
    },
    removeField(childIndex, index) {
      this.pageObj.pageSections[index].pageSectionFields.splice(childIndex, 1)
    },
    getPageStructDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRM.getPagesDetail(
        JSON.parse(this.$route.params.pageMasterId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          console.log("response pageDetailObj", this.pageDetailObj)
          console.log("response pageObj", this.pageObj)
          this.pageObj.pageName = this.pageDetailObj.pageName
          this.pageObj.pageSections = this.pageDetailObj.pageSections
          console.log('this.pageObj.pageSections', this.pageObj.pageSections)
          for (let index = 0; index < this.pageObj.pageSections.length; index++) {
            this.pageObj.pageSections[index].isShow = true
            if (this.pageObj.pageSections[index].pageSectionFields !== null) {
              for (let k = 0; k < this.pageObj.pageSections[index].pageSectionFields.length; k++) {
                this.pageObj.pageSections[index].pageSectionFields[k].selComponentName = this.pageObj.pageSections[index].pageSectionFields[k].componentName
                if (this.pageObj.pageSections[index].pageSectionFields[k].componentMasterId > 0) {
                  this.pageObj.pageSections[index].pageSectionFields[k].isExist = true
                } else {
                  this.pageObj.pageSections[index].pageSectionFields[k].isExist = false
                }
              }
            }
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    saveNewPage() {
      if (this.pageObj.pageName === '') {
        this.pagnameErr = true
        this.isError = true
      }
      for (let index = 0; index < this.pageObj.pageSections.length; index++) {
        if (this.pageObj.pageSections[index].sectionName === '') {
          this.pageObj.pageSections[index].sectionErr = true
          this.isError = true
        }
      }
      console.log(' parseInt(this.$route.params.projectId)',  this.$route.params)
      this.pageObj.pageMasterId = JSON.parse(this.$route.params.pageMasterId)
      this.pageObj.projectDetailId = JSON.parse(this.$route.params.projectId)
      console.log('save', this.pageObj)
      if (this.isError === false) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        HRM.addNewPage(
          this.pageObj,
          response => {
            console.log("response", response.Data)
            this.$router.push({name: 'specificPageDetailInPage', params: {projectId: JSON.parse(this.$route.params.projectId), pageMasterId: response.Data}})
            // this.$router.push({name: 'specificProject', params: {projectId: JSON.parse(this.$route.params.projectId)}})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getComponentList (value) {
      HRM.componentListing(
        0,
        20,
        value,
        'addedDate',
        'DESC',
        true,
        JSON.parse(this.$route.params.projectId),
        response => {
          console.log("response", response.Data)
          this.componentList = response.Data.tableRow === null ? [] : response.Data.tableRow
        },
        error => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    inputFieldReqItem () {
      HRM.inputFieldListing(
        true,
        response => {
          this.inputFieldList = response.Data
          this.shortedList = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
}

::placeholder {
  font-size: 16px;
}
</style>